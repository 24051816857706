import { type ComponentPropsWithoutRef, type ReactNode, useCallback, useId, useRef } from "react";
import { RiAddLine, RiSubtractLine } from "react-icons/ri";

import { Label } from "~/components/ui/forms/label";
import { cn } from "~/utils/classnames";

interface CounterProps extends Omit<ComponentPropsWithoutRef<"input">, "size"> {
  label?: ReactNode;
  min?: number;
  max?: number;
  onStepUp?: (value: number) => void;
  onStepDown?: (value: number) => void;
  infoMessage?: ReactNode;
  errorMessage?: ReactNode;
  loading?: boolean;
  small?: boolean;
}

export const Counter = ({
  id,
  label,
  min = 0,
  max = 9999,
  infoMessage,
  errorMessage,
  onStepUp,
  onStepDown,
  onBlur,
  loading = false,
  small,
  className,
  ...props
}: CounterProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const genId = useId();

  const handleMinusClick = useCallback(() => {
    if (
      inputRef.current &&
      Number(inputRef.current.value) > min &&
      Number(inputRef.current.value) <= max
    ) {
      inputRef.current.stepDown();
    }
    if (onStepDown && inputRef.current) {
      onStepDown(Number(inputRef.current.value));
    }
  }, [onStepDown, min, max]);

  const handlePlusClick = useCallback(() => {
    if (
      inputRef.current &&
      Number(inputRef.current.value) >= min &&
      Number(inputRef.current.value) < max
    ) {
      inputRef.current.stepUp();
    }
    if (onStepUp && inputRef.current) {
      onStepUp(Number(inputRef.current.value));
    }
  }, [onStepUp, min, max]);

  return (
    <div
      className={cn("group relative", loading && "opacity-60", className)}
      aria-disabled={props.disabled}
    >
      {label ? (
        <Label htmlFor={id || genId} required={props.required}>
          {label}
        </Label>
      ) : null}
      <div
        className={cn(
          "relative flex items-center justify-between rounded-full border border-grey-300 bg-white p-1",
          label && "mt-1.5",
          small ? "h-8" : "h-10"
        )}
      >
        <button
          type="button"
          onClick={handleMinusClick}
          disabled={!!(props.value && Number(props.value as string) <= 1)}
          aria-controls={id || genId}
          className="shrink-0 text-grey-500 hover:text-grey-900"
        >
          <RiSubtractLine className="size-5 shrink-0 p-1" />
          <span className="sr-only">Diminuer</span>
        </button>
        <input
          id={id || genId}
          type="number"
          min={min}
          max={max}
          aria-invalid={!!errorMessage}
          aria-describedby={`${id || genId}-info ${id || genId}-error`}
          className="w-10 min-w-0 shrink-0 rounded-md text-center text-sm [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          onBlur={(e) => {
            if (Number(e.currentTarget.value) > max) {
              e.currentTarget.value = max.toString();
            }
            if (Number(e.currentTarget.value) < min) {
              e.currentTarget.value = min.toString();
            }
            if (Number(props.step) && Number(e.currentTarget.value) % Number(props.step) !== 0) {
              e.currentTarget.value = (
                Math.round(Number(e.currentTarget.value) / Number(props.step)) * Number(props.step)
              ).toString();
            }
            if (onBlur) {
              onBlur(e);
            }
          }}
          ref={inputRef}
          {...props}
        />
        <button
          type="button"
          onClick={handlePlusClick}
          disabled={!!(props.value && Number(props.value as string) >= max)}
          aria-controls={id || genId}
          className="shrink-0 text-grey-500 hover:text-grey-900"
        >
          <RiAddLine className="size-5 shrink-0 p-1" />
          <span className="sr-only">Augmenter</span>
        </button>
      </div>
    </div>
  );
};
